import React from "react"
import AddressInput from "./address-input"
import Logo from "../components/images/logo"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

export default function Footer() {
  const result = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "BCorp-gray.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <footer className="relative bg-blue-800 text-white pt-20 pb-16 px-10">
      
      <section className="flex flex-row flex-wrap justify-between hidden md:inline-flex w-full">
        <div className="flex flex-row mr-3">
          <Link to="/">
            <div className="text-white fill-current">
              <Logo></Logo>
            </div>
          </Link>
          <div className="flex flex-row items-center pt-5 md:mx-6">
            <a href="https://www.instagram.com/checksolarcost/" target="_blank">
              <svg
                className="fill-current text-gray-500 mx-3 order-none hover:text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="19.936"
                height="19.937"
                viewBox="0 0 19.936 19.937"
              >
                <path
                  className="a"
                  d="M17.567,19.937H2.37A2.37,2.37,0,0,1,0,17.572V2.365A2.37,2.37,0,0,1,2.37,0h15.2a2.37,2.37,0,0,1,2.37,2.365V17.572A2.37,2.37,0,0,1,17.567,19.937ZM1.869,8.1v9.345a.624.624,0,0,0,.623.623H17.444a.624.624,0,0,0,.623-.623V8.1H15.575a3.216,3.216,0,0,1,.481,1.892,6.065,6.065,0,0,1-12.13,0A3.782,3.782,0,0,1,4.361,8.1ZM9.991,6.166a3.825,3.825,0,1,0,3.834,3.825A3.833,3.833,0,0,0,9.991,6.166Zm4.961-3.674a.624.624,0,0,0-.623.623v1.87a.624.624,0,0,0,.623.623h1.869a.624.624,0,0,0,.623-.623V3.115a.624.624,0,0,0-.623-.623Z"
                />
              </svg>
            </a>

            <a href="https://www.pinterest.com/checksolarcost/" target="_blank">
              <svg
                className="fill-current text-gray-500 mx-3 order-none hover:text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="19.936"
                height="19.936"
                viewBox="0 0 19.936 19.936"
              >
                <path
                  className="a"
                  d="M9.968,0A9.968,9.968,0,0,0,6.335,19.25a9.551,9.551,0,0,1,.035-2.86c.181-.778,1.169-4.955,1.169-4.955a3.594,3.594,0,0,1-.3-1.48c0-1.386.8-2.421,1.8-2.421a1.253,1.253,0,0,1,1.262,1.4,20.078,20.078,0,0,1-.825,3.319,1.447,1.447,0,0,0,1.476,1.8c1.772,0,3.134-1.868,3.134-4.565A3.935,3.935,0,0,0,9.926,5.439a4.315,4.315,0,0,0-4.5,4.326,3.876,3.876,0,0,0,.741,2.275.3.3,0,0,1,.069.286c-.075.315-.244.992-.277,1.131-.044.182-.145.221-.333.133C4.38,13.01,3.6,11.19,3.6,9.728,3.6,6.584,5.887,3.7,10.189,3.7a5.85,5.85,0,0,1,6.145,5.757c0,3.435-2.166,6.2-5.173,6.2a2.668,2.668,0,0,1-2.285-1.145s-.5,1.9-.621,2.37a11.127,11.127,0,0,1-1.239,2.613A9.969,9.969,0,1,0,9.968,0"
                />
              </svg>
            </a>

            <a href="https://www.facebook.com/CheckSolarCost/" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.936"
                height="19.936"
                viewBox="0 0 19.936 19.936"
                className="fill-current text-gray-500 mx-3 order-none hover:text-white"
              >
                <path
                  className="a"
                  d="M19.106,0H.831A.831.831,0,0,0,0,.83V19.106a.831.831,0,0,0,.831.83h9.76V12.46H8.1V9.345h2.492V6.853c0-2.575,1.63-3.842,3.927-3.842a21.454,21.454,0,0,1,2.321.118V5.821H15.247c-1.249,0-1.541.594-1.541,1.465V9.345h3.115L16.2,12.46H13.706l.05,7.476h5.35a.831.831,0,0,0,.83-.83V.83a.831.831,0,0,0-.83-.83"
                />
              </svg>
            </a>
          </div>
        </div>

        <div className="flex flex-row flex-wrap items-center">
          <div className="flex flex-row flex-wrap items-center lg:pr-4">
            <div className="flex opacity-75 items-baseline font-light tracking-wide text-lg text-gray-400">
              
              {/*
              
              <div className="flex flex-col small-p mx-4">
               
                <Link className="pb-3 hover:text-white" to="/how-it-works">
                  How It Works
                </Link>
                <Link className="hover:text-white" to="/products">
                  Products
                </Link>
              </div>
              <div className="flex flex-col small-p mx-4">
                <Link className="pb-3 hover:text-white" to="/about">
                  About
                </Link>
                <Link className="hover:text-white" to="/faqs">
                  FAQ
                </Link>
              </div>
              <div className="flex flex-col small-p mx-4">
                <Link className="pb-3 hover:text-white" to="/privacy">
                  Privacy & Legal
                </Link>
                <a
                  href="https://www.dropbox.com/sh/8h3fdwsjpvdlz9q/AABktcxugPnjlrosfpaW6Ihya?dl=0"
                  target="_blank"
                  className="hover:text-white"
                >
                  Press Kit
                </a>
              </div>
                */}

              <div className="flex flex-col small-p mx-4">
                <span className="pb-3">&copy; 2023 CheckSolarCost, Inc.</span>
                <a
                  href="https://www.instagram.com/tenant.studio/"
                  target="_blank"
                >
                  <p className="small-p font-light tracking-wide text-gray-400 hover:text-white">
                    Site Design by CheckSolarCost
                  </p>
                </a>
              </div>


            </div>
          </div>

          <div className="flex items-center justify-end lg:border-l lg:border-gray-500 opacity-75 text-gray-400 font-light tracking-wide py-4 md:ml-4 md:mt-4 lg:pl-2 lg:mt-0">
            <ul>
              <li className="lg:ml-10 small-p w-full md:w-auto order-none">
                <span className="">132 South State Street</span>
              </li>
              <li className="lg:ml-10 small-p w-full md:w-auto order-none">
                <span className="">Murrieta, CA 92562</span>
              </li>
              <li className="lg:ml-10 small-p w-full md:w-auto order-none">
                <a className="hover:text-white" href="tel:18555842637">
                  +1-855-584-0000
                </a>
              </li>
            </ul>
            {/*<div className="flex items-end">
              <Img
                className="w-8 ml-10 md:self-end"
                fluid={result.file.childImageSharp.fluid}
                alt="B Corp Logo"
              />
  </div>*/}
          </div>
        </div>
      </section>

      {/*This is the mobile footer menu*/}

      <section className="flex flex-row flex-wrap mb-8 border-t border-gray-500 block md:hidden">
        <div className="flex flex-row w-full pt-6">
          <Link to="/">
            <div className="text-white fill-current w-1/2 mr-24">
              <Logo></Logo>
            </div>
          </Link>
          <div className="flex flex-row justify-end w-1/2 ">
            <div className="flex flex-row items-center justify-end">
              <a href="https://www.instagram.com/checksolarcost/" target="_blank">
                <svg
                  className="fill-current text-gray-500 mx-3"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.936"
                  height="19.937"
                  viewBox="0 0 19.936 19.937"
                >
                  <path
                    className="a"
                    d="M17.567,19.937H2.37A2.37,2.37,0,0,1,0,17.572V2.365A2.37,2.37,0,0,1,2.37,0h15.2a2.37,2.37,0,0,1,2.37,2.365V17.572A2.37,2.37,0,0,1,17.567,19.937ZM1.869,8.1v9.345a.624.624,0,0,0,.623.623H17.444a.624.624,0,0,0,.623-.623V8.1H15.575a3.216,3.216,0,0,1,.481,1.892,6.065,6.065,0,0,1-12.13,0A3.782,3.782,0,0,1,4.361,8.1ZM9.991,6.166a3.825,3.825,0,1,0,3.834,3.825A3.833,3.833,0,0,0,9.991,6.166Zm4.961-3.674a.624.624,0,0,0-.623.623v1.87a.624.624,0,0,0,.623.623h1.869a.624.624,0,0,0,.623-.623V3.115a.624.624,0,0,0-.623-.623Z"
                  />
                </svg>
              </a>

              <a href="https://www.pinterest.com/checksolarcost/" target="_blank">
                <svg
                  className="fill-current text-gray-500 mx-3"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.936"
                  height="19.936"
                  viewBox="0 0 19.936 19.936"
                >
                  <path
                    className="a"
                    d="M9.968,0A9.968,9.968,0,0,0,6.335,19.25a9.551,9.551,0,0,1,.035-2.86c.181-.778,1.169-4.955,1.169-4.955a3.594,3.594,0,0,1-.3-1.48c0-1.386.8-2.421,1.8-2.421a1.253,1.253,0,0,1,1.262,1.4,20.078,20.078,0,0,1-.825,3.319,1.447,1.447,0,0,0,1.476,1.8c1.772,0,3.134-1.868,3.134-4.565A3.935,3.935,0,0,0,9.926,5.439a4.315,4.315,0,0,0-4.5,4.326,3.876,3.876,0,0,0,.741,2.275.3.3,0,0,1,.069.286c-.075.315-.244.992-.277,1.131-.044.182-.145.221-.333.133C4.38,13.01,3.6,11.19,3.6,9.728,3.6,6.584,5.887,3.7,10.189,3.7a5.85,5.85,0,0,1,6.145,5.757c0,3.435-2.166,6.2-5.173,6.2a2.668,2.668,0,0,1-2.285-1.145s-.5,1.9-.621,2.37a11.127,11.127,0,0,1-1.239,2.613A9.969,9.969,0,1,0,9.968,0"
                  />
                </svg>
              </a>

              <a href="https://www.facebook.com/CheckSolarCost/" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.936"
                  height="19.936"
                  viewBox="0 0 19.936 19.936"
                  className="fill-current text-gray-500 mx-3"
                >
                  <path
                    className="a"
                    d="M19.106,0H.831A.831.831,0,0,0,0,.83V19.106a.831.831,0,0,0,.831.83h9.76V12.46H8.1V9.345h2.492V6.853c0-2.575,1.63-3.842,3.927-3.842a21.454,21.454,0,0,1,2.321.118V5.821H15.247c-1.249,0-1.541.594-1.541,1.465V9.345h3.115L16.2,12.46H13.706l.05,7.476h5.35a.831.831,0,0,0,.83-.83V.83a.831.831,0,0,0-.83-.83"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-row pt-10 w-full">
          <div className="flex flex-col text-gray-400 opacity-75">
            <small>
              <Link to="/faqs">FAQ</Link>
            </small>
            <small>
              <Link to="/privacy">Privacy & Legal</Link>
            </small>
            <small>
              <a
                href="https://www.dropbox.com/sh/8h3fdwsjpvdlz9q/AABktcxugPnjlrosfpaW6Ihya?dl=0"
                target="_blank"
              >
                Press Kit
              </a>
            </small>
          </div>
        </div>

        <div className="flex flex-row pt-6 w-full">
          <div className="flex flex-col text-gray-400 opacity-75">
            <small>
              <span className="">132 South State Street</span>
            </small>
            <small>
              <span className="">Murrieta, CA 92562</span>
            </small>
            <small>
              <a className="" href="tel:18555842637">
                1-855-584-0000
              </a>
            </small>
          </div>
        </div>

        <div className="flex flex-row flex-wrap items-center font-light tracking-wide opacity-75 text-gray-400 pt-3">
          <div className="flex items-center justify-end">
            <div>
              <small>
                <span className="pb-3">&copy; 2020 Modal Living, Inc.</span>
              </small>
              <a
                href="https://www.instagram.com/tenant.studio/"
                target="_blank"
              >
                <p className="small-p">Site Design by CheckSolarCost</p>
              </a>
            </div>
            <div className="flex items-end">
              {/*<Img
                className="w-8 ml-10 md:self-end"
                fluid={result.file.childImageSharp.fluid}
                alt="B Corp Logo"
              />*/}
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}
